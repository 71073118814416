import { Link, useNavigate } from 'react-router-dom';
import './Banner.scss';

export default function BannerComponent() {
    const navigate = useNavigate();
    function navigateContactPage() {
        navigate("/contact");
    }
    return (
        <section className="banner-section">
            <div className="container banner-strip">
                <h4>Ready to be part of the exciting journey?</h4>
                <p>Reach out to our technical expert today to start transforming you ideas into business.</p>
                <button as={Link} to="/contact" onClick={navigateContactPage} className="btn btn-primary">
                    Contact Us
                    <img src="/assets/images/next.svg" alt='next' className="contact-arrow" />
                </button>
            </div>
        </section>
    );
}