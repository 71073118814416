import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './header.css'
import ndash from './ndash-2.png';

const HeaderComponent = (props) => {
    return (
        <Navbar fixed="top" expand="lg" className="bg-body-tertiary header" data-bs-theme="light" bg="light">
            <Navbar.Brand href="#home">
                <h1 className='brand-name'>
                    <img style={{ height: "50px", marginRight: "20px" }} src={ndash}></img>
                    nDash
                </h1>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <Nav.Link className='nav-items-custom' href="#home">Home</Nav.Link>
                    <Nav.Link className='nav-items-custom' href="#services">Services</Nav.Link>
                    <Nav.Link className='nav-items-custom' href="#contact">Contact</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default HeaderComponent;
