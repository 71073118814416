import imageUrlBuilder from "@sanity/image-url";
import { useEffect, useState } from 'react';
import { client, getClients } from '../../common/sanity-client';
import Loader from "../LoaderComponent/LoaderComponent";
import './ClientComponent.scss';

const builder = imageUrlBuilder(client);
function urlFor(source) {
    return builder.image(source);
}

export default function ClientComponent() {

    const [clientList, setClientList] = useState([]);

    useEffect(() => {
        getClients()
            .then((data) => {
                setClientList(data);
            })
            .catch(console.error);;
    }, []);

    return (
        <section className="client-section" id="clients">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className='section-name'>Our Clients</h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <p className='section-description'>
                            We serve a wide range of companies, from start-ups to multinational corporations, as our client section demonstrates. We take great satisfaction in offering customised IT solutions that promote growth and innovation. To succeed, we form enduring alliances together.
                        </p>
                    </div>
                    <div className="col-lg-12 client-list">
                        {(!clientList || clientList.length === 0)
                            &&
                            <Loader />
                        }
                        <div className='row'>
                            <div className="client-logo-container d-md-flex d-lg-flex align-items-center justify-content-center">
                                {clientList &&
                                    clientList.map((client, index) => (
                                        <div className={`client-logo-block ${index !== clientList.length - 1 ? "card-left-border" : ""}`}>
                                            <img className="client-logo d-flex align-items-center justify-content-center"
                                                src={urlFor(client.image).url()} alt={client.title} />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}