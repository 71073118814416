import { Link, useNavigate } from "react-router-dom";
import "./AboutUsComponent.scss";

export default function AboutUsComponent() {
    const navigate = useNavigate();
    function navigateContactPage() {
        navigate("/contact");
    }
    return (
        <section className='about-us'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h3 className='section-name'>
                            Crafting Scalable Solutions for a <span className="text-ndash-secondary">Smarter</span> Future.
                        </h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <p className='section-description'>
                            At Ndash, we revolutionize the digital world with tailored app development solutions designed to meet your unique needs. We specialize in web and mobile applications, seamlessly integrating AI and cloud technologies. Our mission is to deliver scalable, user-friendly solutions that enhance productivity and streamline operations.
                        </p>
                        <button as={Link} to="/contact" onClick={navigateContactPage} className="btn btn-primary">
                            Contact Us
                            <img alt="next" src="/assets/images/next.svg" className="contact-arrow" />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}