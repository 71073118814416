// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-section {
  padding-top: 90px;
  padding-bottom: 270px;
  background-color: #fff;
  opacity: 90%;
}
.banner-section .banner-strip {
  background-color: #03144a;
  border-radius: 12px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 60px;
  padding-bottom: 60px;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/Banner/Banner.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,qBAAA;EACA,sBAAA;EACA,YAAA;AACJ;AACI;EACI,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,oBAAA;EACA,WAAA;AACR","sourcesContent":[".banner-section {\n    padding-top: 90px;\n    padding-bottom: 270px;\n    background-color: #fff;\n    opacity: 90%;\n\n    .banner-strip {\n        background-color: #03144a;\n        border-radius: 12px;\n        padding-left: 30px;\n        padding-right: 30px;\n        padding-top: 60px;\n        padding-bottom: 60px;\n        color: #fff;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
