// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* --dl-color-gray-500: #595959;
    --dl-color-gray-700: #999999;
    --dl-color-gray-900: #D9D9D9; */
    
.header {
    /* background-color: #141A22 !important; */
    padding: 1% 4% !important;
    color: #fff;
}

.brand-name {
    font-size: 36px !important;
    color: #254a68  !important;
}

.nav-items-custom {
    font-size: 18px !important;
}
`, "",{"version":3,"sources":["webpack://./src/header/header.css"],"names":[],"mappings":"AAAA;;mCAEmC;;AAEnC;IACI,0CAA0C;IAC1C,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,0BAA0B;IAC1B,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["/* --dl-color-gray-500: #595959;\n    --dl-color-gray-700: #999999;\n    --dl-color-gray-900: #D9D9D9; */\n    \n.header {\n    /* background-color: #141A22 !important; */\n    padding: 1% 4% !important;\n    color: #fff;\n}\n\n.brand-name {\n    font-size: 36px !important;\n    color: #254a68  !important;\n}\n\n.nav-items-custom {\n    font-size: 18px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
