import HeaderComponent from '../header/header';
import './home.css'

import websiteDevelopment from './website-development.jpg';
import mobileDevelopment from './mobile-development.jpg';
import ecommerce from './ecommerce.jpg';
import qualityAssurrance from './quality-assurance.jpg';
import cloudSolutions from './cloud.jpg';
import consultancy from './consultancy.jpg';
import ndash from './ndash.png';

import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';

export const HomeComponent = (props) => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_q7b8daw', 'template_fn3cmxm', form.current, {
                publicKey: 'iuCfxYJCuQoAcWuei',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    form.current.reset();
                    alert("We've received your message, and a member of our team will be in touch with you soon.")
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };
    return (
        <div>
            <HeaderComponent />
            <div className='home-section'>
                <div className='container'>
                    <div className='col-lg-8 offset-lg-2 moto-section text-center'>
                        <h1 className='moto-desc'>
                            Transform Your Business with Cutting-Edge Software Solutions
                        </h1>
                        <a href='#contact' className='btn btn-primary mt-4 moto-c-btn'>Get in touch</a>
                    </div>
                </div>
            </div>

            <div className="services-section" id='services'>
                <div className="container">
                    <div className="col-lg-12">
                        <div className="row mt-4">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="what-we-do-card p-3 text-center" style={{ height: "100%" }}>
                                            <div className='vertical-center'>
                                                <h2 className='section-header section-text'>What we offer</h2>
                                                <hr style={{ color: "#fff" }} />
                                                <p className='section-services-desc'>Immerse yourself in a profound sense of dedication, unwavering passion, and unparalleled excellence as you engage with our superior, top-notch services.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="service-card">
                                                    <img className='service-card-image' src={websiteDevelopment}></img>
                                                    <p className='service-card-desc'>Web design and development</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="service-card">
                                                    <img className='service-card-image' src={mobileDevelopment}></img>
                                                    <p className='service-card-desc'>Mobile app development</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="service-card">
                                                    <img className='service-card-image' src={ecommerce}></img>
                                                    <p className='service-card-desc'>E-Commerce solutions</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mt-3">
                                                <div className="service-card">
                                                    <img className='service-card-image' src={qualityAssurrance}></img>
                                                    <p className='service-card-desc'>Quality Assurance and testing</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mt-3">
                                                <div className="service-card">
                                                    <img className='service-card-image' src={cloudSolutions}></img>
                                                    <p className='service-card-desc'>Cloud Solutions</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mt-3">
                                                <div className="service-card">
                                                    <img className='service-card-image' src={consultancy}></img>
                                                    <p className='service-card-desc'>Consultancy and IT Advisory</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="details-section">
                <div className="container">
                    <div className="col-lg-6 offset-lg-3 text-center">
                        <h1 className='details-header'>Elevate your digital presence</h1>
                    </div>
                    <div className="col-lg-8 offset-lg-2 text-center mt-3">
                        <p className='design-desc'>Discover unparalleled software services designed to transform your business's online operations</p>
                    </div>
                </div>
            </div>
            <div className="contact-section" id='contact'>
                <div className="container">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <form ref={form} onSubmit={sendEmail}>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label>Name *</label>
                                            <input name='from_name' type='text' className='form-control mt-2'></input>
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Email *</label>
                                            <input name='from_email' type='text' className='form-control mt-2'></input>
                                        </div>
                                        <div className="col-lg-12 mt-4">
                                            <label>Message *</label>
                                            <textarea name='message' className='form-control mt-2'></textarea>
                                        </div>
                                        <div className="col-lg-12 mt-4">
                                            <button type="submit" className="btn btn-primary">Send message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-5 offset-lg-1 pt-4">
                                <h3 className='contact-header'>Get in touch</h3>
                                <p className='contact-desc'>Your communication is important to us. Please send us a message, and we will prioritize a timely response from our team.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="contact-area" id="contact">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 offset-lg-3">
                            <div class="contact-content text-center">
                                <a href="#"><img src={ndash} className='footer-logo' alt="logo" /></a>

                                <div class="hr"></div>
                                <h6 className='address'>3/314, Manappally North, Thazhava, Thodiyoor, Kollam, Kerala, 690574</h6>
                                <h6>
                                    <a className="contact-items-link" href="tel:+919884402166">+91 9884 402 166</a>
                                    <span>|</span>
                                    <a className="contact-items-link" href="tel:+919176791836">+91 9176 791 836</a>
                                    <span>|</span>
                                    <a className="contact-items-link" href="mailto:contact@ndashdigital.com">contact@ndashdigital.com</a>
                                </h6>
                                <div class="contact-social">
                                    <ul>
                                        <li>
                                            <a class="hover-target" href="">
                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0,0,256,256">
                                                    <g fill="#254a68" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{ mixBlendMode: "normal" }}><g transform="scale(8.53333,8.53333)"><path d="M9.99805,3c-3.859,0 -6.99805,3.14195 -6.99805,7.00195v10c0,3.859 3.14195,6.99805 7.00195,6.99805h10c3.859,0 6.99805,-3.14195 6.99805,-7.00195v-10c0,-3.859 -3.14195,-6.99805 -7.00195,-6.99805zM22,7c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1c-0.552,0 -1,-0.448 -1,-1c0,-0.552 0.448,-1 1,-1zM15,9c3.309,0 6,2.691 6,6c0,3.309 -2.691,6 -6,6c-3.309,0 -6,-2.691 -6,-6c0,-3.309 2.691,-6 6,-6zM15,11c-2.20914,0 -4,1.79086 -4,4c0,2.20914 1.79086,4 4,4c2.20914,0 4,-1.79086 4,-4c0,-2.20914 -1.79086,-4 -4,-4z"></path></g></g>
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a class="hover-target" href="">
                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0,0,256,256">
                                                    <g fill="#254a68" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{ mixBlendMode: "normal" }}><g transform="scale(8.53333,8.53333)"><path d="M15,3c-6.627,0 -12,5.373 -12,12c0,6.016 4.432,10.984 10.206,11.852v-8.672h-2.969v-3.154h2.969v-2.099c0,-3.475 1.693,-5 4.581,-5c1.383,0 2.115,0.103 2.461,0.149v2.753h-1.97c-1.226,0 -1.654,1.163 -1.654,2.473v1.724h3.593l-0.487,3.154h-3.106v8.697c5.857,-0.794 10.376,-5.802 10.376,-11.877c0,-6.627 -5.373,-12 -12,-12z"></path></g></g>
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a class="hover-target" href="https://www.linkedin.com/in/ndash-private-limited-489179307/" target='_blank'>
                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0,0,256,256">
                                                    <g fill="#254a68" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{ mixBlendMode: "normal" }}><g transform="scale(5.12,5.12)"><path d="M41,4h-32c-2.76,0 -5,2.24 -5,5v32c0,2.76 2.24,5 5,5h32c2.76,0 5,-2.24 5,-5v-32c0,-2.76 -2.24,-5 -5,-5zM17,20v19h-6v-19zM11,14.47c0,-1.4 1.2,-2.47 3,-2.47c1.8,0 2.93,1.07 3,2.47c0,1.4 -1.12,2.53 -3,2.53c-1.8,0 -3,-1.13 -3,-2.53zM39,39h-6c0,0 0,-9.26 0,-10c0,-2 -1,-4 -3.5,-4.04h-0.08c-2.42,0 -3.42,2.06 -3.42,4.04c0,0.91 0,10 0,10h-6v-19h6v2.56c0,0 1.93,-2.56 5.81,-2.56c3.97,0 7.19,2.73 7.19,8.26z"></path></g></g>
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-12 text-center mt-4'>
                        <p>Copyright &copy; 2024 All Rights Reserved.</p>
                    </div>
                </div>
            </section>
            {/* <div className='footer'>

            </div> */}
        </div>
    );
}
