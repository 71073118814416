import imageUrlBuilder from "@sanity/image-url";
import { useEffect, useState } from 'react';
import { client, getTeams } from '../../common/sanity-client';
import Loader from "../LoaderComponent/LoaderComponent";
import './TestimonialComponent.scss';

const builder = imageUrlBuilder(client);
function urlFor(source) {
    return builder.image(source);
}

function getFirstCharacters(name) {
    var nameArray = name.trim().split(" ");
    if (nameArray.length > 1) {
        return (nameArray[0][0] + nameArray[1][0]).toUpperCase();
    } else {
        return (nameArray[0][0] + nameArray[0][1]).toUpperCase();
    }
}

export default function TestimonialsComponent() {

    const [testimonialList, setTestimonialList] = useState([]);

    useEffect(() => {
        getTeams()
            .then((data) => {
                data.forEach((testimonial) => {
                    testimonial.image = testimonial.image ? urlFor(testimonial.image).url() : null;
                });
                setTestimonialList(data);
                // console.log(data)
                return;
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <section className='testimonial-container' id='success-stories'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-8'>
                        <h3 className='section-name'>
                            Meet Our Team
                        </h3>
                        <p className="section-desc">
                            At nDash, we are a passionate team of innovators, engineers, and strategists dedicated to building cutting-edge IT solutions. Driven by collaboration and creativity, we turn challenges into opportunities, delivering impactful results for our clients.
                        </p>
                    </div>
                </div>
                <div className='row testimonial-list'>
                    {(!testimonialList || testimonialList.length === 0)
                        &&
                        <Loader />
                    }
                    {testimonialList &&
                        testimonialList.map((testimonial, index) => (
                            <div key={index} className='col-lg-4'>
                                <div className='card testimonial-card'>
                                    <div className="testimonial-img"
                                        nametext={testimonial.image ? "" : getFirstCharacters(testimonial.author)}
                                        style={{
                                            "--bg-img": testimonial.image ? "url('" + testimonial.image + "')" : ""
                                        }}>
                                    </div>
                                    <div className='testimonial-contents'>
                                        <h6 className='testimonial-name'>{testimonial.author}</h6>
                                        <h6 className='testimonial-role'>{testimonial.role}</h6>
                                        <img alt="quotes" className='testimonial-quotes' src='/assets/images/quotes.svg'></img>
                                        <p className='testimonial-text'>{testimonial.content}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </section>
    );
}