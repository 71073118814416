import urlBuilder from "@sanity/image-url";
import { getImageDimensions } from "@sanity/asset-utils";

const imageBuilder = urlBuilder({
    projectId: "liehngtt",
    dataset: "production",
});

export const RichTextComponents = {
    types: {
        image: ({ value, isInline }) => {
            const { width, height } = getImageDimensions(value);
            return (
                <img
                    src={imageBuilder.image(value).url()}
                    alt={value.alt || " "}
                    loading="lazy"
                    className={`sanity-image`}
                    style={{ aspectRatio: width / height }}
                />
            );
        },
        code: ({ value }) => {
            const code = value.code;
            const filename = value.filename;
            const language = value.language;

            return (
                <div className="bg-gray-900 rounded-lg p-4 mb-16">
                    <div className="flex justify-between mb-2">
                        <p className="text-gray-400">{filename}</p>
                        <p className="text-gray-400">
                            language: <span className="text-white">{language}</span>
                        </p>
                    </div>
                    <pre className="p-2 bg-gray-800 rounded-lg overflow-auto">
                        <code className="block text-sm font-mono text-white">{code}</code>
                    </pre>
                </div>
            );
        },
    },

    block: {
        h1: ({ children }) => (
            <h1 className="col-lg-12 ndash-h1 font-bold mb-16 text-left">{children}</h1>
        ),
        h2: ({ children }) => (
            <h2 className="col-lg-12 ndash-h2 text-3xl font-bold mb-16 text-left">{children}</h2>
        ),
        h3: ({ children }) => (
            <h3 className="col-lg-12 ndash-h3 font-bold mb-16 text-left">{children}</h3>
        ),
        h4: ({ children }) => (
            <h4 className="col-lg-12 ndash-h4 text-2xl font-bold mb-16 text-left">{children}</h4>
        ),
        h5: ({ children }) => (
            <h4 className="col-lg-12 ndash-h5 text-2xl font-bold mb-16 text-left">{children}</h4>
        ),
        h6: ({ children }) => (
            <h4 className="col-lg-12 ndash-h6 text-2xl font-bold mb-16 text-left">{children}</h4>
        ),
        blockquote: ({ children }) => (
            <blockquote className="border-l-4 pl-4 mb-16 italic text-gray-700 border-gray-300">
                {children}
            </blockquote>
        ),
        normal: ({ children }) => <p className="col-lg-12 ndash-paragraph-md-l4-l7-custom mb-16">{children}</p>,
    },

    list: {
        bullet: ({ children }) => (
            <ul className="col-lg-12 list-disc ml-8 mb-16 mir-paragraph-md-l4-l7-custom">{children}</ul>
        ),
        number: ({ children }) => (
            <ol className="col-lg-12 list-decimal ml-8 mb-16 mir-paragraph-md-l4-l7-custom">{children}</ol>
        ),
        checkmarks: ({ children }) => (
            <ol className="col-lg-12 m-auto text-lg mir-paragraph-md-l4-l7-custom">{children}</ol>
        ),
    },
    marks: {
        link: ({ children, value }) => {
            // const rel = !value.href || !value.href.startsWith("/")
            //     ? "noreferrer noopener"
            //     : undefined;
            return (
                <a
                    href={value.href}
                    target="_blank"
                    className="text-blue-500 hover:text-blue-700"
                >
                    {children}
                </a>
            );
        },
    },
};