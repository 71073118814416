import { createClient } from '@sanity/client';

export const client = createClient({
    projectId: 'liehngtt',
    dataset: 'production',
    useCdn: true, // set to `false` to bypass the edge cache
    apiVersion: '2024-12-16', // use current date (YYYY-MM-DD) to target the latest API version
    // token: process.env.SANITY_SECRET_TOKEN // Needed for certain operations like updating content or accessing previewDrafts perspective
});

export async function getServices() {
    const services = await client.fetch('*[_type == "services"]')
    return services;
}

export async function getClients() {
    const posts = await client.fetch('*[_type == "clients"]')
    return posts;
}

export async function getServiceBySlug(slug) {
    const service = await client.fetch('*[_type == "services" && slug.current == "' + slug + '"]')
    return service;
}

export async function getSuccessStories() {
    const successStories = await client.fetch('*[_type == "successStories"]')
    return successStories;
}

export async function getTeams() {
    const teams = await client.fetch('*[_type == "testimonials"]')
    return teams;
}