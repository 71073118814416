import imageUrlBuilder from "@sanity/image-url";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { client, getServices } from '../../common/sanity-client';
import Loader from '../LoaderComponent/LoaderComponent';
import './ServiceComponent.scss';
import ServicesContent from '../../lib/sanity-backup/services';

const builder = imageUrlBuilder(client);
function urlFor(source) {
    return builder.image(source);
}

export default function ServicesComponent() {

    const [servicesList, setServicesList] = useState([]);
    const navigate = useNavigate();

    function navigateToDetailedView(data) {
        navigate("/service/" + data.slug.current);
    }

    useEffect(() => {
        getServices()
            .then((data) => {
                data.forEach((service) => {
                    service.image = urlFor(service.image).url();
                });
                setServicesList(data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                if (servicesList.length === 0) {
                    ServicesContent.forEach((service) => {
                        service.image = "../../../assets/images/services/" + service.slug.current + ".avif";
                    })
                    setServicesList(ServicesContent);
                }
            });
    }, []);

    return (
        <section className="service-section" id='services'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className='section-name'>Our Services</h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <p className='section-description'>
                            We specialize in crafting AI-powered, automation-driven solutions tailored to your unique business challenges. Our expert team ensures seamless technology integration to drive innovation. Empower your business with reliable, scalable, and future-ready solutions.
                        </p>
                    </div>
                    <div className="col-lg-12 services-list">
                        <div className='row'>
                            {(!servicesList ||
                                servicesList.length === 0) &&
                                <div className='col-lg-6'>
                                    <Loader />
                                </div>
                            }
                            {servicesList &&
                                servicesList.length > 0 &&
                                servicesList.map((service, index) => (
                                    <div className='col-lg-3 col-md-4 col-12 col-sm-12 service-card-parent'>
                                        <div onClick={() => navigateToDetailedView(service)} className='service-card card' style={{ backgroundColor: service.colorCode }}>
                                            <img className="service-cover card-img-top" src={service.image} alt={service.title}></img>
                                            <div className='service-details'>
                                                <h4 className='service-title'>{service.title}</h4>
                                                <p className='service-description'>{service.description}</p>
                                                <img onClick={() => navigateToDetailedView(service)} src='/assets/images/next.png' className='section-next' alt='Read more' />
                                            </div>
                                        </div>

                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}