import { Route, Routes } from 'react-router-dom';
import './App.css';
import { HomeComponent } from './home/home';

const App = () => {
   return (
      <>
         <Routes>
            <Route index path="/" element={<HomeComponent />} />
         </Routes>
      </>
   );
};

export default App;
